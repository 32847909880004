import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SoundCloudAudio from 'soundcloud-audio'
import styled from 'styled-components'
import { VolumeUp, VolumeOff } from 'styled-icons/fa-solid'

const PlayerButton = (props) => (
  <button
    type="button"
    className={props.className}
    onClick={(e) => props.onClick()}
  >
    {props.children}
  </button>
)

const StyledVolumeButton = styled(PlayerButton)`
  border: none;
  height: 40px;
  padding: 10px 5px;
  background-color: transparent;

  & svg {
    width: 100%;
    height: 100%;
    color: #ddd;
  }
`

const StyledVolumeControl = styled.div`
  min-width: 30px;
  width: 30px;
  height: 100%;

  position: relative;
  button:hover + input {
    display: block;
  }
`
const VolumeSlider = styled.input`
  position: absolute;
  bottom: 95px;
  left: -51px;
  z-index: 1;
  transition: height 100ms;
  transform: translateZ(0);
  background-color: #f2f2f2;
  border: 1px solid transparent;
  outline: 0;
  cursor: pointer;
  transform: rotate(270deg);

  display: none;

  &:hover {
    display: block;
  }
`
const playerButton = function (SVGComponent, onClick) {
  return (
    <StyledVolumeButton onClick={(e) => onClick(e)}>
      <SVGComponent />
    </StyledVolumeButton>
  )
}

class VolumeControl extends Component {
  constructor(props) {
    super(props)

    this.handleVolumeChange = this.handleVolumeChange.bind(this)
    this.handleMute = this.handleMute.bind(this)
  }

  handleVolumeChange(e) {
    const {
      onVolumeChange,
      onToggleMute,
      soundCloudAudio,
      isMuted,
    } = this.props
    const xPos = e.target.value / 100
    const mute = xPos <= 0 && !isMuted

    if (soundCloudAudio && !isNaN(soundCloudAudio.audio.volume)) {
      soundCloudAudio.audio.volume = xPos
      soundCloudAudio.audio.muted = mute
    }

    if (mute !== isMuted) {
      onToggleMute && onToggleMute.call(this, mute, e)
    }

    onVolumeChange && onVolumeChange.call(this, xPos, e)
  }

  handleMute(e) {
    const { onToggleMute, soundCloudAudio } = this.props

    if (soundCloudAudio && !isNaN(soundCloudAudio.audio.muted)) {
      soundCloudAudio.audio.muted = !soundCloudAudio.audio.muted
    }

    onToggleMute && onToggleMute.call(this, !this.props.isMuted, e)
  }

  render() {
    const { volume, isMuted } = this.props

    let value = volume * 100 || 0

    if (value < 0 || isMuted) {
      value = 0
    }

    if (value > 100) {
      value = 100
    }

    return (
      <StyledVolumeControl>
        {playerButton(isMuted ? VolumeOff : VolumeUp, (e) =>
          this.handleMute(e)
        )}
        <VolumeSlider
          type="range"
          min="0"
          max="100"
          step="1"
          value={value}
          onChange={this.handleVolumeChange}
        />
      </StyledVolumeControl>
    )
  }
}

VolumeControl.propTypes = {
  className: PropTypes.string,
  rangeClassName: PropTypes.string,
  volume: PropTypes.number,
  onVolumeChange: PropTypes.func,
  onToggleMute: PropTypes.func,
  soundCloudAudio: PropTypes.instanceOf(SoundCloudAudio),
}

VolumeControl.defaultProps = {
  volume: 1,
  isMuted: 0,
}

export default VolumeControl
