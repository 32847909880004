import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import Header from './Header'
import Sidebar from './Sidebar'
import SoundCloudAudioPlayer from './SoundCloudAudioPlayer'

import { sidebarWidth } from '../css-globals'
import styled, { css } from 'styled-components'
import 'normalize.css'

const Container = styled.div`
  display: grid;
  height: 100vh;
  width: 100vw;
  will-change: transform;
  grid-template-rows: 0px 1fr auto;
  grid-template-columns: ${sidebarWidth} 1fr;
  grid-template-areas:
    'header  header'
    'sidebar content'
    'sidebar content';

  overflow: none;

  @media only screen and (max-width: 45em) {
    grid-template-rows: auto 1fr auto;
    grid-template-columns: auto 1fr;
  }
`
const ContentContainer = styled.div`
  grid-area: content;
  overflow-y: ${(props) => (props.isHomePath ? 'hidden' : 'auto')};
`
class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
    this.handleButtonClick = this.handleButtonClick.bind(this)
  }

  handleButtonClick() {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  render() {
    const { children } = this.props
    const menuIsOpen = this.state.menuOpen

    const isHomePath = this.props.location.pathname === '/'
    return (
      <>
        <Helmet
          title="JP Wright"
          meta={[
            { name: 'description', content: 'Sample' },
            {
              name: 'keywords',
              content: 'sample, something, Loud Mouth, Dem Suckaz',
            },
          ]}
        />

        <Container openMenu={menuIsOpen}>
          <Sidebar isOpen={menuIsOpen} />
          <Header buttonClick={this.handleButtonClick} menuOpen={menuIsOpen} />
          <ContentContainer menuIsOpen={menuIsOpen} isHomePath={isHomePath}>
            {children}

            <SoundCloudAudioPlayer
              openMenu={this.state.menuOpen}
              clientId={`${process.env.GATSBY_SOUND_CLOUD_KEY}`}
              resolveUrl="https://soundcloud.com/loudmouthbeats/sets/loud-mouth-originals/s-N78Zm"
            />
          </ContentContainer>
        </Container>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.object.isRequired,
}

export default Layout
