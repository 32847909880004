import React from 'react'
import { Link } from 'gatsby'
import * as PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { transition, sidebarWidth } from '../css-globals'

import {
  Github,
  Linkedin,
  StackOverflow,
  Soundcloud,
} from 'styled-icons/fa-brands'
import { Envelope } from 'styled-icons/fa-solid'

const StyledSocialMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin: 0px 16px 20px;
  flex-shrink: 0;
`

const SocialMenuItem = styled.a`
  flex-basis: 12%;
  list-style-type: none;
  color: #fff;
  margin: 0px 5px;

  & svg {
    display: block;
  }

  transition: all 0.2s ease-in-out;
  transform: translateY(0) scale(1);

  &:hover {
    transform: translateY(-3px) scale(1.1);
    color: #abbddb;
  }
`

function socialLink(WrappedComponent, url) {
  return (
    <SocialMenuItem href={url} target="_blank">
      <WrappedComponent />
    </SocialMenuItem>
  )
}
const LineDivider = styled.li`
  margin: 20px 0px;
  border-top: 1px solid;
  height: 2px;
  list-style: none;
  border-top-color: white;
`

const StyledLink = styled(Link)`
  padding: 16px 16px;
  margin: 0px;
  transition: all 0.1s ease-out;
  transform: translateX(0) scale(1);

  &:hover {
    transform: translateX(-10px) scale(1.1);
    background-color: #d0dbed;
    color: rgba(0, 0, 0, 0.6);
  }
  font-size: ${(props) => (props.emphasized ? `150%` : `100%`)};
`

const StyledSidebar = styled.nav`
  grid-area: sidebar;
  position: absolute;
  height: 100%;
  color: transparent;
  background-color: #666;
  overflow-y: auto;
  width: ${sidebarWidth};
  transition: 0.15s left linear;
  ${({ isOpen }) =>
    isOpen
      ? css`
          box-shadow: 5px 5px 10px grey;
        `
      : ``}

  @media only screen and (min-width: 45em) {
    box-shadow: 2px 5px 10px grey;
  }
  @media only screen and (max-width: 45em) {
    left: ${(props) => (props.isOpen ? '0' : '-200px')};
  }
  z-index: 100;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding: 100px 0px 200px;

  & a {
    line-height: 1;
    text-decoration: none;
    color: #fff;
  }

  & p {
    text-align: right;
    padding: 30px 16px 0px;
    color: #fff;
    font-size: 12px;
  }
`

const Sidebar = ({ isOpen }) => (
  <StyledSidebar isOpen={isOpen}>
    <StyledLink to="/" emphasized>
      JP Wright
    </StyledLink>

    <p>Software developer and music producer interested in all things audio.</p>

    <LineDivider />

    {/* <StyledLink to="/">Software developer</StyledLink> */}
    <StyledLink to="/music">Musician</StyledLink>
    <StyledLink to="/blog">Blog</StyledLink>
    <StyledLink to="/resume">Résumé</StyledLink>

    <LineDivider />

    <StyledSocialMenu>
      {socialLink(Github, 'https://github.com/loudmouth')}
      {socialLink(Linkedin, 'https://www.linkedin.com/in/wrightjp')}
      {socialLink(Envelope, 'mailto:jpwright31@gmail.com')}
      {socialLink(
        StackOverflow,
        'http://stackoverflow.com/users/4068264/loudmouth'
      )}
      {socialLink(Soundcloud, 'https://soundcloud.com/loudmouthbeats')}
    </StyledSocialMenu>
  </StyledSidebar>
)

Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

export default Sidebar
