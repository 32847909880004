import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Hamburger = styled.button`
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: #000;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  transform: scale(0.8);

  &:focus {
    outline: none;
  }

  /* &:hover {
    opacity: 0.7;
  } */

  display: none;
  @media only screen and (max-width: 45em) {
    display: block;
  }

  ${({ menuOpen }) =>
    menuOpen &&
    css`
      /* &:hover {
        opacity: 0.7;
      } */
      & > span > span,
      & > span > span::before,
      & > span > span::after {
        background-color: #000;
      }
      & > span > span::before {
        transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
      }

      & > span > span::after {
        transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
      }
    `};
`

const HamburgerBox = styled.span`
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
`

const HamburgerInner = styled.span`
  display: block;
  top: 50%;
  margin-top: -2px;
  &,
  &::before,
  &::after {
    width: 40px;
    height: 4px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  &::before,
  &::after {
    content: '';
    display: block;
  }
  &::before {
    top: -10px;
  }
  &::after {
    bottom: -10px;
  }
`
const StyledHeader = styled.header`
  grid-area: header;
  transition: transform 0.2s linear;
  width: 100%;
  background-color: #fff;
  display: none;
  /* box-shadow: 5px 5px 10px grey; */
  box-shadow: 0px 2px 10px #666;
  z-index: 101;

  @media only screen and (max-width: 45em) {
    display: unset;
    transform: translateY(0) translateX(0);
  }
`
const Header = ({ buttonClick, menuOpen }) => (
  <StyledHeader>
    <Hamburger onClick={buttonClick} menuOpen={menuOpen}>
      <HamburgerBox>
        <HamburgerInner />
      </HamburgerBox>
    </Hamburger>
  </StyledHeader>
)

Header.propTypes = {
  buttonClick: PropTypes.func.isRequired,
}

export default Header
