import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Play, StepBackward, StepForward, Pause } from 'styled-icons/fa-solid'

import VolumeControl from './VolumeControl'

import { Progress, Timer } from 'react-soundplayer/components'
import { withSoundCloudAudio } from 'react-soundplayer/addons'

import 'react-soundplayer/styles/progress.css'

const StyledProgress = styled(Progress)`
  max-width: 400px;
`
const FooterContainer = styled.div`
  position: absolute;
  z-index: 100;
  display: block;
  width: 100%;
  bottom: 0;
  background-color: transparent;
  mix-blend-mode: difference;
  width: 100%;
  /* filter: invert(100%); */
`
const StyledFooter = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
`

const Blur = styled.footer`
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  /* filter: blur(10px); */
`
const CurrentTrackInfo = (props) => (
  <StyledTrackInfo>
    <h3>{props.track ? props.track.user.username : ''}</h3>
    <h2>{props.track ? props.track.title : ''}</h2>
  </StyledTrackInfo>
)
CurrentTrackInfo.propTypes = {
  track: PropTypes.object,
}

const StyledTrackInfo = styled.div`
  color: #ddd;
  width: 250px;
  display: flex;
  flex-direction: column;

  & h3 {
    font-size: 14px;
    padding: 10px 5px 0px;
    margin: 0;
  }
  & h2 {
    font-size: 14px;
    padding: 10px 5px;
    margin: 0;
  }
`

const PlayerButton = (props) => (
  <button
    type="button"
    className={props.className}
    onClick={(e) => props.onClick()}
  >
    {props.children}
  </button>
)

PlayerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
}
const StyledPlayerButton = styled(PlayerButton)`
  border: none;
  &:focus {
    outline: none;
  }
  height: 100%;
  /* width: 30px; */
  min-width: 34px;
  background-color: transparent;
  border-radius: 4px;
  padding: 8px;
  & svg {
    display: block;
    color: #ddd;
  }
  &:hover {
    background-color: #fc561e;
  }
`
const playerButton = function (SVGComponent, onClick) {
  return (
    <StyledPlayerButton onClick={(e) => onClick(e)}>
      <SVGComponent />
    </StyledPlayerButton>
  )
}

const StyledTimer = styled(Timer)`
  color: #ddd;
  padding: 0px 15px;
  width: 140px;
`
class AudioPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
    }
    this.prevIndex = this.prevIndex.bind(this)
    this.togglePlayPause = this.togglePlayPause.bind(this)
    this.nextIndex = this.nextIndex.bind(this)
  }

  nextIndex() {
    const { soundCloudAudio } = this.props
    soundCloudAudio && soundCloudAudio.next()
    const { playlist } = this.props
    let { activeIndex } = this.state

    if (activeIndex >= playlist.tracks.length - 1) {
      this.setState({ activeIndex: 0 })
      return
    }

    if (activeIndex || activeIndex === 0) {
      this.setState({ activeIndex: ++activeIndex })
    }
  }

  togglePlayPause() {
    const { playing, soundCloudAudio } = this.props

    if (!playing) {
      soundCloudAudio &&
        soundCloudAudio.play({
          playlistIndex: soundCloudAudio._playlistIndex,
        })
    } else {
      soundCloudAudio && soundCloudAudio.pause()
    }
  }

  prevIndex() {
    const { soundCloudAudio } = this.props
    soundCloudAudio && soundCloudAudio.previous()

    const { playlist } = this.props
    let { activeIndex } = this.state

    if (activeIndex <= 0) {
      this.setState({ activeIndex: playlist.tracks.length - 1 })
      return
    }

    if (activeIndex || activeIndex === 0) {
      this.setState({ activeIndex: --activeIndex })
    }
  }

  componentDidMount() {
    const { soundCloudAudio } = this.props
    soundCloudAudio.on('loadedmetadata', function () {
      soundCloudAudio._playlistIndex = 0
    })
  }

  render() {
    const {
      soundCloudAudio,
      playlist,
      duration,
      currentTime,
      openMenu,
    } = this.props

    if (!playlist) {
      return <StyledFooter>Loading...</StyledFooter>
    }

    const tracks = playlist.tracks
    // Set soundCloudAudio playlist index so that it loads.
    soundCloudAudio._playlistIndex = this.state.activeIndex

    return (
      <FooterContainer>
        <Blur />
        <StyledFooter>
          {playerButton(StepBackward, (e) => this.prevIndex())}
          {playerButton(soundCloudAudio.playing ? Pause : Play, (e) =>
            this.togglePlayPause()
          )}
          {playerButton(StepForward, (e) => this.nextIndex())}

          <StyledProgress
            innerClassName="rounded-left bg-white"
            value={(currentTime / duration) * 100 || 0}
            {...this.props}
            cl
          />
          <StyledTimer {...this.props} />
          <VolumeControl {...this.props} />
          <CurrentTrackInfo track={tracks[this.state.activeIndex]} />
        </StyledFooter>
      </FooterContainer>
    )
  }
}

AudioPlayer.propTypes = {
  openMenu: PropTypes.bool.isRequired,
  resolveUrl: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
}

const SoundCloudAudioPlayer = withSoundCloudAudio(AudioPlayer)
export default SoundCloudAudioPlayer
